import React, { useState, useEffect } from "react";
import { Div, Text, Row, Col, Anchor, Button, Icon, Image } from "atomize";
import logo from "../../img/chaind_logo.png";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const toggleShowMenu = () => {
    // setIsMobile(!isMobile);
    setShowMenu(!showMenu);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setShowNavbar(currentScrollPos < prevScrollPos || currentScrollPos === 0);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Call handleResize on mount and on window resize
    window.addEventListener("resize", handleResize);
    handleResize();

    // Remove event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Div
      w="100%"
      bg="#121212"
      d="flex"
      textColor="white"
      align="center"
      justify="center">
      <Text>Request access today, limited availability.</Text>
      <Div
        pos="fixed"
        bg="none"
        top="0"
        left="0"
        w="100%"
        h="10rem"
        shadow="1"
        p={{ x: "1rem", y: "1.5rem" }}
        d="flex"
        align="center"
        justify="space-between"
        zIndex="100"
        textColor="white"
        transition="all 0.3s ease-in-out"
        transform={showNavbar ? "translateY(0)" : "translateY(-100%)"}>
        <Row p={{ l: { xl: "5rem", xs: "2rem" }, r: "3rem" }}>
          <Anchor textWeight="bold" textColor="white" href="/" d="flex">
            <Image
              w="3rem"
              src={logo}
              alt="chaind logo"
              position="relative"
              m={{ r: "1rem" }}
            />
            <Text tag="h1" textSize="display1">
              Chaind
            </Text>
          </Anchor>
        </Row>
        {!isMobile ? (
          <Div d="flex" flexDir="column">
            <Row p={{ r: "2rem" }} textColor="white" flexWrap="nowrap">
              <Col>
                <Text
                  tag="a"
                  textSize="paragraph"
                  textColor="white"
                  cursor="pointer"
                  m={{ r: "1rem" }}>
                  <Anchor textColor="white" href="/">
                    Home
                  </Anchor>
                </Text>
              </Col>

              <Col>
                <Text
                  tag="a"
                  textSize="paragraph"
                  textColor="white"
                  cursor="pointer"
                  m={{ r: "1rem" }}>
                  <Anchor textColor="white" href="/Services">
                    Services
                  </Anchor>
                </Text>
              </Col>
              <Col>
                <Text
                  tag="a"
                  textSize="paragraph"
                  textColor="white"
                  cursor="pointer"
                  m={{ r: "1rem" }}>
                  <Anchor
                    textColor="white"
                    href="https://calendly.com/jonjcoleman/15-minute-consultation/">
                    Contact
                  </Anchor>
                </Text>
              </Col>
            </Row>
          </Div>
        ) : (
          <Div d="flex" flexDir="column">
            <Button
              onClick={() => setShowMenu(true)}
              d={{ xs: "flex", md: "none" }}
              bg="transparent"
              p="0"
              textColor="white">
              <Text textSize="heading">☰</Text>
            </Button>
            {isMobile && showMenu && (
              <Div
                bg="#121212"
                textColor="white"
                w="100vw"
                h="100vh"
                pos="fixed"
                top="0"
                left="0"
                d="flex"
                textAlign="center"
                flexDir="column"
                align="center"
                transition="1s"
                onClick={() => setShowMenu(false)}>
                <Div pos="fixed" top="0" right="0" m="2rem">
                  <Icon
                    name="CloseSolid"
                    color="white"
                    onClick={() => setShowMenu(false)}
                    size="20px"
                    cursor="pointer"
                  />
                </Div>
                <Div
                  d="flex"
                  flexDir="column"
                  textAlign="center"
                  m={{ t: "5rem" }}>
                  <Text
                    tag="a"
                    textSize="title"
                    textColor="white"
                    cursor="pointer"
                    p={{ y: "1rem" }}
                    w="100vw"
                    bg="#181818">
                    <Anchor textColor="white" href="/">
                      Home
                    </Anchor>
                  </Text>
                  <Text
                    tag="a"
                    textSize="title"
                    textColor="white"
                    cursor="pointer"
                    p={{ y: "1rem" }}
                    w="100vw"
                    bg="#181818">
                    <Anchor textColor="white" href="/Services">
                      Services
                    </Anchor>
                  </Text>

                  <Text
                    tag="a"
                    textSize="title"
                    textColor="white"
                    cursor="pointer"
                    p={{ y: "1rem" }}
                    w="100vw"
                    bg="#181818">
                    <Anchor
                      textColor="white"
                      href="https://calendly.com/jonjcoleman/15-minute-consultation/">
                      Contact
                    </Anchor>
                  </Text>
                </Div>
              </Div>
            )}
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default Navbar;
