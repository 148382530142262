import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Div, Input, Button } from "atomize";

const EmailSubscriptionForm = () => {
  const postUrl =
    "https://chaind.us9.list-manage.com/subscribe/post?u=c7de6fd37f1b09cde3a297983&amp;id=40926e6b10&amp;f_id=00e8c7e0f0";

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <Div d="flex" flexDir="column" align="center" justify="center">
        <Div d="flex" flexDir={{ xl: "row", xs: "column" }}>
          <Input
            bg="#111"
            border="none"
            textColor="white"
            ref={(node) => (email = node)}
            type="email"
            placeholder="Enter your email address"
            textSize="15px"
            w="15rem"
            m={{ r: { xl: "0.25rem", xs: "0" }, b: { xl: "0", xs: "0.5rem" } }}
          />

          <Button bg="blue" onClick={submit}>
            Subscribe
          </Button>
        </Div>
        {status === "success" && (
          <Div m={{ t: "0.5rem" }}>Thank you for subscribing!</Div>
        )}
      </Div>
    );
  };

  return (
    <div>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default EmailSubscriptionForm;
