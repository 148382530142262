import React, { useState, Component } from "react";
// import { Collapse } from "./Collapse";

import {
  Div,
  StyleReset,
  ThemeProvider,
  Icon,
  Text,
  Row,
  Col,
  Button,
  Image,
  Anchor,
  scrollTo,
  Modal,
} from "atomize";

// import jon from "../../img/jon.png";
import blockchain_development from "../../img/blockchain_development.png";
import crypto_commerce from "../../img/crypto_commerce.jpg";
import phone from "../../img/blocks_on_phone.jpg";
import purple_chain from "../../img/purple_chain.jpg";
import purple_blocks from "../../img/purple_blocks.jpg";
import block_in_middle from "../../img/block_in_middle.jpg";
import valiants from "../../img/valiants.png";
import server_chain from "../../img/server_chain.jpg";
import Subscribe from "../Subscribe/EmailSubscriptionForm.js";
import GetQuote from "../Subscribe/GetQuote";

const theme = {
  colors: {
    black900: "#1d1d1e",
  },
  shadows: {
    "new-shadow": "0 16px 24px -2px rgba(0, 0, 0, 0.2)",
  },
};

export const MainPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animate, setAnimate] = useState(false);

  const handleScroll = () => {
    const element = document.getElementById("whatOffer");
    const elementPosition = element.getBoundingClientRect().top;
    const screenHeight = window.innerHeight;

    if (elementPosition < screenHeight * 0.7) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  };
  return (
    <Div
      bg="#0e0f10"
      textColor="white"
      minH="100vh"
      w="100vw"
      maxW="100vw"
      d="flex"
      flexDir="column"
      align="center"
      fontFamily="primary"
      textWeight="500">
      <Div
        w="100vw"
        h="100vh"
        d="flex"
        flexDir="column"
        textAlign="center"
        justify="center"
        p={{ x: { xs: "2rem", xl: "10rem" }, t: "0rem" }}>
        <Text textSize={{ xl: "90px", xs: "70px" }}>
          The future of <span style={{ color: "#375bd2" }}>Web3</span>.
        </Text>
        <Text
          textColor="gray700"
          tag="p"
          textSize="title"
          p={{ x: { sm: "1rem", md: "0.5rem" }, y: "2rem" }}>
          Helping businesses navigate the rapidly evolving world of blockchain.
        </Text>
        <Div d="flex" justify="center" p="0.5rem">
          <Button
            bg="#375bd2"
            hoverBg="blue"
            transition="true"
            id="blueButton"
            h="3.5rem"
            w="10rem"
            rounded="15px"
            onClick={() => scrollTo("#unlockBlockchain")}
            suffix={
              <Icon
                name="DownArrow"
                size="16px"
                color="white"
                m={{ l: "1rem" }}
              />
            }
            shadow="3"
            hoverShadow="4"
            m={{ r: "1rem" }}>
            Get Started
          </Button>
        </Div>

        {/* 
        <Div
          h="15rem"
          w="15rem"
          m={{ x: "1rem", y: "3rem" }}
          shadow="new-shadow"
          rounded="lg"
          d="flex"
          flexDir="column"
          align="center"
          justify="center"
          textColor="medium"
          textSize="subheader"
          bg="white">
          <Image
            h="7.5rem"
            rounded="50%"
            w="7.5rem"
            src={jon}
            m="6px"
            shadow="4"
          />
          <Text textSize="subheader">Jon Coleman</Text>
          <Text textSize="caption">Blockchain Integration Advisor</Text>
        </Div> */}
      </Div>
      <Div id="unlockBlockchain"></Div>
      <Div
        Div
        m={{ t: "5rem" }}
        textAlign="center"
        d="flex"
        flexDir={{ xl: "row", xs: "column" }}
        w="100%">
        <Div
          m={{ x: { xl: "5rem", xs: "0rem" } }}
          w={{ xl: "30vw", xs: "100%" }}
          d="block"
          textAlign={{ xl: "left", xs: "center" }}
          align="center"
          justify="center">
          <Div w="100%" d="flex" justify={{ xl: "left", xs: "center" }}>
            <Div
              d="flex"
              w="8rem"
              h="2.5rem"
              bg="#222"
              rounded="15px"
              m={{ b: "1.5rem", l: "1rem" }}
              align="center"
              justify="center">
              <Text textSize="12px" textColor="gray900">
                Powerful Software
              </Text>
            </Div>
          </Div>
          <Text textSize="50px" textWeight="bold" p={{ x: "1rem" }}>
            Unlock the power of{" "}
            <span style={{ color: "#375bd2" }}>blockchain</span>
          </Text>
          <Text
            textSize="title"
            textColor="gray900"
            p={{ t: "2rem", x: "1rem" }}>
            Blockchain is changing the game in every industry, and Chaind is
            here to help you stay ahead of the curve.
          </Text>
          <Div
            w="100%"
            d="flex"
            justify={{ xl: "left", xs: "center" }}
            m={{ l: { xl: "1rem", xs: "0rem" } }}>
            <Button
              bg="#375bd2"
              hoverBg="blue"
              transition="true"
              id="blueButton"
              h="3.5rem"
              w="10rem"
              rounded="15px"
              m={{ t: "1rem" }}
              textSize="subheader"
              onClick={() => scrollTo("#whatOffer")}>
              Learn More
            </Button>
          </Div>
        </Div>
        <Div
          w={{ xl: "50%", sm: "100%" }}
          m={{ t: { xl: "0rem", xs: "4rem" } }}
          d="flex"
          justify={{ xl: "right", xs: "center" }}
          align="center">
          <Div
            d="flex"
            flexDir="column"
            justify="center"
            align="center"
            h="35rem">
            <Image
              w={{ xs: "20rem", md: "25rem", xl: "30rem" }}
              maxH={{ xs: "15rem", md: "25rem", xl: "20rem" }}
              src={phone}
              alt="mobile blockchain"
              shadow="4"
              rounded="30px"
              position="relative"
              transform={{
                xl: "",
                xs: "translateX(-10px)",
              }}
            />
            <Image
              w={{ xs: "20rem", md: "25rem", xl: "30rem" }}
              maxH={{ xs: "15rem", md: "25rem", xl: "20rem" }}
              src={purple_chain}
              alt="purple blockchain"
              shadow="4"
              rounded="30px"
              transform={{
                xl: "translateX(50px) translateY(-250px)",
                xs: "translateX(10px) translateY(-250px)",
              }}
            />
          </Div>
        </Div>
      </Div>
      <Div p={{ t: "5rem" }} textAlign="center" id="whatOffer">
        <Text textSize={{ xl: "60px", xs: "40px" }}>What We Offer</Text>
        <Div
          d="flex"
          flexDir={{ xs: "column", lg: "row" }}
          align="center"
          m={{ t: "2rem" }}>
          <Div
            h={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            w={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            hoverBg="#222"
            transition="true"
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            rounded="30px"
            bg="#141516"
            d="block"
            align="center"
            justify="center"
            textAlign="left"
            textColor="white"
            textSize="subheader">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2Zm10.798 11c-.453-1.27-1.76-3-4.798-3-3.037 0-4.345 1.73-4.798 3H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-1.202Z" />
              </svg>
            </Div>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              p={{ b: "2rem", x: "3rem" }}>
              Web3 Consulting
            </Text>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Fully tailored blockchain integration solutions for your company
            </Text>
          </Div>
          <Div
            h={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            w={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            hoverBg="#222"
            transition="true"
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            rounded="30px"
            d="block"
            align="center"
            justify="center"
            textAlign="left"
            textColor="white"
            textSize="subheader"
            bg="#141516">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                padding="0"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z" />
              </svg>
            </Div>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              p={{ b: "2rem", x: "3rem" }}>
              Blockchain Development
            </Text>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Expert EVM blockchain developers
            </Text>
          </Div>
          <Div
            h={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            w={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            hoverBg="#222"
            transition="true"
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            bg="#141516"
            rounded="30px"
            d="block"
            align="center"
            textAlign="left"
            justify="center"
            textColor="white"
            textSize="subheader">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class=""
                viewBox="0 0 16 16">
                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
              </svg>
            </Div>

            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              p={{ b: "2rem", x: "3rem" }}>
              DApps
            </Text>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Decentralized applications which are fully customizable to meet
              your company's needs
            </Text>
          </Div>
        </Div>
        <Div d="flex" flexDir={{ xs: "column", lg: "row" }} align="center">
          <Div
            h={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            w={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            hoverBg="#222"
            transition="true"
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            rounded="30px"
            bg="#141516"
            d="block"
            align="center"
            justify="center"
            textAlign="left"
            textColor="white"
            textSize="subheader">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
              </svg>
            </Div>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              p={{ b: "2rem", x: "3rem" }}>
              NFTs
            </Text>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Provably verify your art, identity or assets on a blockchain which
              is best suited for your needs
            </Text>
          </Div>
          <Div
            h={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            w={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            hoverBg="#222"
            transition="true"
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            rounded="30px"
            d="block"
            align="center"
            justify="center"
            textAlign="left"
            textColor="white"
            textSize="subheader"
            bg="#141516">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                />
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
              </svg>
            </Div>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              p={{ b: "2rem", x: "3rem" }}>
              Tokens
            </Text>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Plug-and-play template solutions for those looking to launch their
              own token
            </Text>
          </Div>
          <Div
            h={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            w={{ xl: "25rem", md: "23rem", xs: "21rem" }}
            hoverBg="#222"
            transition="true"
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            bg="#141516"
            rounded="30px"
            d="block"
            align="center"
            textAlign="left"
            justify="center"
            textColor="white"
            textSize="subheader">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
              </svg>
            </Div>

            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              p={{ b: "2rem", x: "3rem" }}>
              Smart Contracts
            </Text>
            <Text
              textSize={{ xl: "title", xs: "subheader" }}
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Fully customizable smart contracts developed to excecute
              transactions on the blockchain
            </Text>
          </Div>
        </Div>
      </Div>

      <Div p={{ t: "5rem" }} textAlign="left">
        <Div d="flex" flexDir={{ xs: "column", md: "row" }} p={{ x: "10px" }}>
          <Div
            p="20px"
            m={{
              x: { xs: "2rem", sm: "1rem", lg: "2rem", xl: "5rem" },
              sm: { b: "1rem" },
            }}>
            <Div p={{ b: "20px" }}>
              <Text
                textSize={{ xs: "display1", md: "heading" }}
                textWeight="bold">
                Blockchain Development
              </Text>
            </Div>
            <Div>
              <Text textSize="title" p={{ b: "15px" }}>
                Building decentralized apps that redefine how we interact with
                the digital world.
              </Text>
              <Text textSize="subheader" textColor="gray900">
                We specialize in creating cutting-edge decentralized
                applications that leverage the power of blockchain technology.
                From smart contract development to front-end interface design,
                we offer end-to-end development services that help your project
                stand out in the crowded blockchain ecosystem. Whether you're
                building a new project from scratch or looking to migrate an
                existing app to a decentralized architecture, our experienced
                team can help you every step of the way.
              </Text>
            </Div>
            <Div p={{ t: "1rem" }}>
              <Button
                hoverBg="blue"
                transition="true"
                h="3rem"
                w="10rem"
                bg="#375bd2"
                rounded="15px">
                <Anchor textColor="white" href="/Services">
                  Learn More
                </Anchor>
              </Button>
            </Div>
          </Div>
          <Div
            d="flex"
            justify="center"
            p="20px"
            m={{ xs: "0", lg: { r: "6rem" } }}
            align="center">
            <Image
              w={{ xs: "20rem", md: "25rem", xl: "30rem" }}
              maxH={{ xs: "15rem", md: "25rem", xl: "20rem" }}
              src={blockchain_development}
              alt="blockchain development"
              m="5px"
              shadow="4"
              rounded="30px"
            />
          </Div>
        </Div>
        <Div m={{ y: "10rem" }}></Div>
        <Div d="flex" flexDir={{ xs: "column", md: "row" }} p={{ x: "10px" }}>
          <Div
            order={{ xs: "1", md: "0" }}
            d="flex"
            justify="center"
            p="20px"
            m={{ xs: "0", lg: { l: "6rem" } }}
            align="center">
            <Image
              w={{ xs: "20rem", md: "25rem", xl: "30rem" }}
              maxH={{ xs: "20rem", md: "20rem", xl: "20rem" }}
              src={crypto_commerce}
              alt="pay with crypto"
              m="5px"
              shadow="4"
              rounded="30px"
            />
          </Div>
          <Div
            p="20px"
            m={{
              x: { xs: "2rem", sm: "1rem", lg: "2rem", xl: "5rem" },
            }}>
            <Div p={{ b: "20px" }}>
              <Text textSize="heading" textWeight="bold">
                Web3 Consulting
              </Text>
            </Div>
            <Div>
              <Text textSize="title" p={{ b: "15px" }}>
                Revolutionize the way you do business with blockchain
                technology.
              </Text>
              <Text textSize="subheader" textColor="gray900">
                Our Web3 integration solutions are designed to help your
                business transform into a company of the future. We provide both
                template and fully tailored solutions that include everything
                from rewards programs to smart contract development. Whether
                you're an e-commerce business looking to accept Bitcoin or a
                corporation looking to create a blockchain-based customer
                platform, we can help you navigate the complex world of Web3.
              </Text>
            </Div>
            <Div p={{ t: "1rem" }}>
              <Button
                hoverBg="blue"
                transition="true"
                h="3rem"
                w="10rem"
                bg="#375bd2"
                rounded="15px">
                <Anchor textColor="white" href="/Services">
                  Learn More
                </Anchor>
              </Button>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div>{/* <Collapse /> */}</Div>
      {/* <Div p={{ t: "5rem" }} textAlign="center">
        <Text textColor="black" textSize="display2" p="2rem">
          Testimonials
        </Text>
        <Div d="flex" flexDir={{ xs: "column", lg: "row" }}>
          <Div w="25rem" m={{ xs: "10px", lg: "50px" }} h="15rem">
            <Div>
              <Text>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat."
              </Text>
            </Div>
            <Div d="flex" flexDir="row" float="left" align="center" p="20px">
              <Div
                d="flex"
                bg="black"
                rounded="50%"
                align="center"
                justify="center"
                m={{ r: "10px" }}>
                <Image h="3rem" w="3rem" src={valiants} p="12px" />
              </Div>
              <Div>
                <Text textSize="paragraph">DeFi Valiant</Text>
                <Text textSize="tiny">Investment DAO</Text>
              </Div>
            </Div>
          </Div>
          <hr></hr>
          <Div w="25rem" m={{ xs: "10px", lg: "50px" }}>
            <Div>
              <Text>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat."
              </Text>
            </Div>
            <Div d="flex" flexDir="row" float="left" align="center" p="20px">
              <Div
                d="flex"
                bg="black"
                rounded="50%"
                align="center"
                justify="center"
                m={{ r: "10px" }}>
                <Image h="3rem" w="3rem" src={valiants} p="12px" />
              </Div>
              <Div>
                <Text textSize="paragraph">DeFi Valiant</Text>
                <Text textSize="tiny">Investment DAO</Text>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Div
        w="100vw"
        m={{ y: "7rem" }}
        bg="#375bd2"
        textAlign="center"
        d="flex"
        flexDir={{ xs: "column", lg: "row" }}
        justify="center"
        align="center"
        textColor="white">
        <Div m={{ x: { xl: "8rem", md: "6rem" }, y: "3rem" }}>
          <Text textSize="display3" textWeight="bold" m={{ b: "10px" }}>
            100%
          </Text>
          <Text textSize="subheading" textColor="gray500">
            Our Client Satisfaction
          </Text>
        </Div>
        <Div m={{ x: "8rem", y: "3rem" }}>
          <Text textSize="display3" textWeight="bold" m={{ b: "10px" }}>
            $17B
          </Text>
          <Text textSize="subheading" textColor="gray500">
            Web3 Market Size
          </Text>
        </Div>
      </Div>
      <Div
        w="100%"
        d="flex"
        flexDir="column"
        id="pricing"
        justify="center"
        align="center">
        <Text textSize={{ xl: "60px", xs: "30px" }} padding={{ x: "1rem" }}>
          Simple transparent{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "#375bd2",
            }}>
            pricing
          </span>
          .
        </Text>
        <Div
          d="flex"
          flexDir={{ xs: "column", lg: "row" }}
          align="center"
          m={{ t: "2rem" }}>
          <Div
            h={{ xl: "40rem", xs: "50rem" }}
            w={{ xl: "25rem", xs: "22rem" }}
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            rounded="30px"
            bg="#141516"
            d="block"
            align="center"
            justify="center"
            textAlign="left"
            textColor="white"
            textSize="subheader">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <Text
                border="1px solid"
                borderColor="#333"
                p="0.75rem"
                w="7rem"
                d="flex"
                align="center"
                justify="center"
                rounded="15px">
                Development
              </Text>
            </Div>

            <Text
              textSize="title"
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Expert Web3 development services to build your digital future
            </Text>
            <Div
              w="100%"
              d="flex"
              flexDir="row"
              justify="center"
              align="center">
              <Button
                hoverBg="blue"
                transition="true"
                w={{ xl: "20rem", xs: "18rem" }}
                h="3.5rem"
                bg="#375bd2"
                rounded="15px"
                onClick={() => setIsModalOpen(true)}>
                Request a Quote
              </Button>
            </Div>
            <Div p={{ y: "2rem", x: "3rem" }}>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Customizable smart contracts</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>NFT projects</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Customized DApps</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Verified smart constract audit</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Fast integration time</Text>
              </Div>
            </Div>
          </Div>
          <Div
            h={{ xl: "40rem", xs: "50rem" }}
            w={{ xl: "25rem", xs: "22rem" }}
            m={{ x: "1rem", y: "1rem" }}
            shadow="new-shadow"
            rounded="30px"
            d="block"
            align="center"
            justify="center"
            textAlign="left"
            textColor="white"
            textSize="subheader"
            bg="#141516">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <Text
                border="1px solid"
                borderColor="#333"
                p="0.75rem"
                w="12rem"
                d="flex"
                align="center"
                justify="center"
                rounded="15px">
                Consulting
              </Text>
            </Div>

            <Text
              textSize="title"
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              We will guide you through the entire process of integration from
              start to finish
            </Text>
            <Div
              w="100%"
              d="flex"
              flexDir="row"
              justify="center"
              align="center">
              <Button
                hoverBg="blue"
                transition="true"
                w={{ xl: "20rem", xs: "18rem" }}
                h="3.5rem"
                bg="#375bd2"
                rounded="15px"
                onClick={() => setIsModalOpen(true)}>
                Request a quote
              </Button>
            </Div>
            <Div p={{ y: "2rem", x: "3rem" }}>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Fully tailored blockchain solutions</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Expert Web3 consulting advice</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Web3 integration strategies</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Regulatory compliance & legal advice</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Customized strategies for integration</Text>
              </Div>
            </Div>
          </Div>
          <Div
            h={{ xl: "40rem", xs: "50rem" }}
            w={{ xl: "25rem", xs: "22rem" }}
            m={{ x: "1rem", y: "5rem" }}
            shadow="new-shadow"
            bg="#141516"
            rounded="30px"
            d="block"
            align="center"
            textAlign="left"
            justify="center"
            textColor="white"
            textSize="subheader">
            <Div p={{ t: "3rem", b: "2rem", x: "3rem" }}>
              <Text
                border="1px solid"
                borderColor="#333"
                p="0.75rem"
                w="7rem"
                d="flex"
                align="center"
                justify="center"
                rounded="15px">
                Full Service
              </Text>
            </Div>

            <Text
              textSize="title"
              textColor="gray900"
              p={{ b: "2rem", x: "3rem" }}>
              Chaind will manage and maintain your entire project and team
            </Text>
            <Div
              w="100%"
              d="flex"
              flexDir="row"
              justify="center"
              align="center">
              <Button
                hoverBg="blue"
                transition="true"
                w={{ xl: "20rem", xs: "18rem" }}
                h="3.5rem"
                bg="#375bd2"
                rounded="15px"
                onClick={() => setIsModalOpen(true)}>
                Request a quote
              </Button>
            </Div>
            <Div p={{ y: "2rem", x: "3rem" }}>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Full Web3 project management</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Post project maintenance</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Expert Web3 development strategies</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Access to exclusive Web3 networks</Text>
              </Div>
              <Div d="flex" flexDir="row" m={{ y: "1rem" }} align="center">
                <Div
                  d="flex"
                  align="center"
                  justify="center"
                  w="2rem"
                  h="2rem"
                  bg="#375bd2"
                  rounded="10px"
                  m={{ r: "0.5rem" }}>
                  <Icon name="Checked" color="white" size="20px" />
                </Div>
                <Text>Increased efficiency and productivity</Text>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        bg="#222"
        rounded="20px"
        pos="fixed"
        d="flex"
        flexDir="column">
        <GetQuote />
      </Modal>
      <Div
        justify="center"
        textAlign="center"
        align="center"
        d="flex"
        flexDir="column"
        bg="rgb(25, 25, 25)"
        rounded="30px"
        w={{ xl: "90%", xs: "80%" }}
        p={{ y: "4rem" }}>
        <Text
          textSize={{ xl: "50px", xs: "40px" }}
          textWeight="bold"
          m={{ b: "20px" }}>
          How it works
        </Text>
        <Text textSize="20px" p={{ x: "2rem" }} textColor="black200">
          Chaind streamlines the process of Web3 integration.
        </Text>
        <Div
          d="flex"
          flexDir="row"
          justify="center"
          bg="rgb(30, 30, 30)"
          rounded="30px"
          m={{ x: "5rem", y: "2rem" }}>
          <Div
            m={{ y: "3rem" }}
            textAlign="center"
            d="flex"
            flexDir={{ xs: "column", lg: "row" }}
            justify="center"
            align="center">
            <Div
              d="flex"
              flexDir="column"
              m={{ x: { xl: "1rem" }, y: { xs: "2rem" } }}
              w="20rem"
              justify="center"
              align="center">
              <Div m={{ b: "0.25rem" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  fill="currentColor"
                  viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                </svg>
              </Div>
              <Text textSize="title" textColor="gray900">
                Connect
              </Text>
            </Div>
            <hr></hr>
            <Div
              d="flex"
              flexDir="column"
              m={{ x: { xl: "1rem" }, y: { xs: "2rem" } }}
              w="15rem"
              justify="center"
              align="center">
              <Div m={{ b: "0.25rem" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  viewBox="0 0 16 16">
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
              </Div>
              <Text textSize="title" textColor="gray900">
                Integrate
              </Text>
            </Div>
            <hr></hr>
            <Div
              d="flex"
              flexDir="column"
              m={{ x: { xl: "1rem" }, y: { xs: "2rem" } }}
              w="15rem"
              justify="center"
              align="center">
              <Div m={{ b: "0.25rem" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  viewBox="0 0 16 16">
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                </svg>
              </Div>
              <Text textSize="title" textColor="gray900">
                Optimize
              </Text>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div
        m={{ t: "3rem", b: "4rem" }}
        w="100%"
        justify="center"
        textAlign="center"
        align="center"
        d="flex"
        flexDir={{ xs: "column", xl: "row" }}
        p="4rem">
        <Div
          w={{ xl: "40%", xs: "100%" }}
          d="flex"
          flexDir="column"
          textAlign="left"
          m={{ r: { xl: "15rem", xs: "0rem" } }}>
          <Text textSize="50px">
            With our streamlined integration process, we can get you on the
            blockchain in{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#375bd2",
              }}>
              as fast as 4 days
            </span>
            .
            <Text
              m={{ t: "0.5rem" }}
              style={{
                fontWeight: "normal",
                fontSize: "16px",
                color: "#808080",
              }}>
              (Certain Terms May Apply)
            </Text>
          </Text>

          <Text textSize="title" textColor="gray900" m={{ t: "1rem" }}>
            Are you ready to take your business to the next level using the
            power of blockchain technology, but worried about long integration
            times? Our streamlined integration process allow us to provide
            highly competitive turnaround times.
          </Text>
          <Div
            d="flex"
            flexDir="row"
            m={{ t: "3rem" }}
            align="center"
            bg="#222">
            <Div h="4rem" w="0.25rem" bg="#375bd2" m={{ r: "1rem" }}></Div>
            <Text>"The fastest technology integration company in Web3."</Text>
          </Div>
        </Div>
        <Div
          w={{ xl: "25rem", xs: "20rem" }}
          h={{ xl: "25rem", xs: "20rem" }}
          rounded="30px"
          d="flex"
          align="center"
          justify="center"
          bg="#333"
          m={{ t: "3rem" }}>
          <Div
            w={{ xl: "20rem", xs: "15rem" }}
            h={{ xl: "20rem", xs: "15rem" }}
            d="flex"
            align="center"
            justify="center"
            bg="#333">
            <Div
              w={{ xl: "30rem", xs: "25rem" }}
              h={{ xl: "20rem", xs: "15rem" }}
              bgImg={block_in_middle}
              rounded="15px"
              position="relative"
              bgSize="cover"
              bgPos="center"
            />
          </Div>
        </Div>
      </Div>
      <Div
        m={{ t: "3rem", b: "4rem" }}
        w="100%"
        justify="center"
        textAlign="center"
        align="center"
        d="flex"
        flexDir="column"
        p={{ xl: "4rem", xs: "2rem" }}>
        <Div
          d="flex"
          flexDir="column"
          align="center"
          bg="#375bd2"
          w={{ xl: "80%", xs: "90%" }}
          p={{ x: { xl: "10rem", xs: "2rem" }, y: "6rem" }}
          rounded="30px">
          <Div
            w="8rem"
            h="3rem"
            d="flex"
            align="center"
            justify="center"
            border="1px solid"
            borderColor="white"
            rounded="15px"
            opacity="0.7"
            m={{ b: "2rem" }}>
            Get In Touch
          </Div>
          <Text textSize={{ xl: "60px", xs: "30px" }} m={{ b: "4rem" }}>
            Want to learn more about Web3?
          </Text>
          <Subscribe />
          {/* <Button
            w={{ xl: "20rem", xs: "15rem" }}
            hoverBg="pink"
            transition="true"
            h="3.5rem"
            bg="black"
            rounded="15px"
            textSize={{ xl: "subheader", xs: "paragraph" }}>
            <Anchor
              textColor="white"
              href="https://calendly.com/jonjcoleman/15-minute-consultation/">
              Book Your{" "}
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "#375bd2",
                }}>
                FREE
              </span>{" "}
              Consultation
            </Anchor>
          </Button> */}
        </Div>
      </Div>
    </Div>
  );
};
