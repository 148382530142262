import React, { Component } from "react";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  Div,
  StyleReset,
  ThemeProvider,
  Icon,
  Text,
  Row,
  Col,
  Button,
  Image,
} from "atomize";
import Navbar from "./components/top-navbar/Navbar";
import { MainPage } from "./components/MainPage/MainPage";
import { Services } from "./components/Services/Services";

const debug =
  process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

// 1. Create a client engine instance
const engine = new Styletron();

const theme = {
  colors: {
    black900: "#1d1d1e",
  },
  shadows: {
    "new-shadow": "0 16px 24px -2px rgba(0, 0, 0, 0.2)",
  },
};

function App({ children, href }) {
  return (
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <ThemeProvider theme={theme}>
        <StyleReset />
        <Navbar />
        <Router>
          <Switch>
            <Route exact path="/">
              <MainPage />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
          </Switch>
          <head>
            <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
          </head>
        </Router>
      </ThemeProvider>
    </StyletronProvider>
  );
}

export default App;
