import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Div, Input, Button, Modal, Icon, Text, ThemeProvider } from "atomize";

const GetQuote = () => {
  const postUrl =
    "https://chaind.us9.list-manage.com/subscribe/post?u=c7de6fd37f1b09cde3a297983&amp;id=40926e6b10&amp;f_id=00e8c7e0f0";

  const CustomForm = ({ status, message, onValidated }) => {
    let name, email, company, phone;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        MMERGE2: name.value,
        MMERGE3: company.value,
        MMERGE1: phone.value,
      });

    return (
      <Div>
        <Input
          ref={(node) => (name = node)}
          type="text"
          placeholder="Name"
          textColor="white"
          textSize="15px"
          w="100%"
          m={{ b: "1rem" }}
          bg="#111"
          border="none"
        />
        <Input
          ref={(node) => (email = node)}
          type="email"
          textColor="white"
          placeholder="Email Address"
          textSize="15px"
          w="100%"
          m={{ b: "1rem" }}
          bg="#111"
          border="none"
        />
        <Input
          ref={(node) => (company = node)}
          type="text"
          textColor="white"
          placeholder="Company"
          textSize="15px"
          w="100%"
          m={{ b: "1rem" }}
          bg="#111"
          border="none"
        />
        <Input
          ref={(node) => (phone = node)}
          type="text"
          textColor="white"
          placeholder="Phone Number"
          textSize="15px"
          w="100%"
          m={{ b: "1rem" }}
          bg="#111"
          border="none"
        />
        {status === "sending" && <Icon name="Loading3" size="20px" />}
        {status === "error" && (
          <Text textColor="danger800">Error, try again.</Text>
        )}
        {status === "success" && (
          <Text textColor="success800">
            Thank you for submitting! Our team will reach out within 24 hours.
          </Text>
        )}

        <Button
          onClick={submit}
          bg="blue"
          w="100%"
          m={{ t: "2rem" }}
          disabled={status === "sending"}>
          {status === "sending" ? (
            <Icon name="Loading" size="18px" color="white" />
          ) : (
            "Submit"
          )}
        </Button>
      </Div>
    );
  };

  return (
    <Div>
      <Text
        textSize="heading"
        textAlign="center"
        textColor="white"
        m={{ b: "2rem" }}>
        Get a Quote
      </Text>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </Div>
  );
};

export default GetQuote;
