import React, { Component } from "react";

import {
  Div,
  StyleReset,
  ThemeProvider,
  Icon,
  Text,
  Row,
  Col,
  Button,
  Image,
  Container,
  Anchor,
} from "atomize";
import btcpay from "../../img/btcpay.png";
import ethereum from "../../img/ethereum.png";
import server_chain from "../../img/server_chain.jpg";
import infinite from "../../img/infinite.jpg";
import Waitlist from "../Waitlist/Waitlist";
import Subscribe from "../Subscribe/EmailSubscriptionForm.js";

const theme = {
  colors: {
    black900: "#1d1d1e",
  },
  shadows: {
    "new-shadow": "0 16px 24px -2px rgba(0, 0, 0, 0.2)",
  },
};

export const Services = () => {
  return (
    <Div w="100vw" bg="#0e0f10">
      <Div
        textColor="white"
        w="100%"
        d="flex"
        flexDir={{ xs: "column", xl: "row" }}
        fontFamily="primary"
        textWeight="500"
        justify="center"
        p={{ x: "1rem", y: "6rem" }}>
        <Div
          m={{
            l: { xs: "0rem", md: "4rem" },
            r: { xs: "0rem", md: "8rem" },
            t: "4rem",
          }}
          w={{ xl: "40rem" }}
          d="flex"
          flexDir="column"
          justify="center"
          textAlign={{ xs: "center", md: "left" }}
          align="center"
          p={{ x: { xs: "2rem" } }}>
          <Text textSize={{ xs: "40px", xl: "60px" }}>
            <span style={{ color: "#375bd2" }}>Seamless</span> and{" "}
            <span style={{ color: "#375bd2" }}>secure</span> digital
            transformation has never been easier.
          </Text>
          <Text
            textSize={{ xs: "paragraph", sm: "subheader" }}
            p={{ t: "20px" }}
            textColor="gray900">
            Our team provides fully tailored solutions to seamlessly integrate
            blockchain technology into your business or online store, so you can
            get a headstart in the digital world. Our team of experts will work
            closely with you to understand your unique needs and challenges and
            create a customized plan that aligns with your needs.
          </Text>
        </Div>
        <Div
          d="flex"
          align="center"
          justify="center"
          p={{ t: { xl: "0", xs: "2rem" } }}>
          <Image
            w={{ xs: "20rem", xl: "30rem" }}
            h={{ xs: "15rem", xl: "20rem" }}
            src={infinite}
            alt="Web3 infinity"
            rounded="30px"
            p="5px"></Image>
        </Div>
      </Div>
      <Div
        w="100%"
        d="flex"
        align="center"
        justify="center"
        flexDir={{ xs: "column", lg: "row" }}>
        <Div
          order={{ xs: "1", md: "0" }}
          align="center"
          p={{ t: { xl: "0", xs: "2rem" } }}>
          <Image
            w={{ xs: "20rem", md: "30rem", xl: "35rem" }}
            src={ethereum}
            alt="Ethereum"
            m={{ x: "4rem" }}
            rounded="30px"
            shadow="4"></Image>
        </Div>
        <Div
          w={{ xs: "80%", xl: "50%" }}
          justify="center"
          align="center"
          m={{ l: "0rem" }}
          p={{ r: { xl: "4rem", xs: "0rem" } }}
          textColor="white">
          <Text textSize="50px" textAlign={{ xs: "center", xl: "left" }}>
            Why Chaind?
          </Text>
          <Text
            p={{ y: "15px" }}
            textColor="gray900"
            textAlign={{ xs: "center", xl: "left" }}>
            Powerful tools to supercharge your business
          </Text>
          <Div d="flex" m={{ b: "15px" }}>
            <Icon
              name="CBChecked"
              color="#375bd2"
              size="40px"
              p={{ r: "10px" }}
            />
            <Div p={{ l: "1rem" }}>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textWeight="bold"
                p={{ t: "5px" }}>
                Customization
              </Text>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textColor="gray900">
                We understand that every business is unique. That's why we
                provide fully tailored solutions to meet your specific goals and
                needs.
              </Text>
            </Div>
          </Div>
          <Div d="flex" m={{ b: "15px" }}>
            <Icon
              name="CBChecked"
              size="40px"
              color="#375bd2"
              p={{ r: "10px" }}
            />
            <Div p={{ l: "1rem" }}>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textWeight="bold"
                p={{ t: "5px" }}>
                Innovation
              </Text>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textColor="gray900">
                We're constantly exploring new possibilities in Web3. Our
                solutions are forward-thinking and will always safely keep you
                ahead of the curve.
              </Text>
            </Div>
          </Div>
          <Div d="flex" m={{ b: "15px" }}>
            <Icon
              name="CBChecked"
              color="#375bd2"
              size="40px"
              p={{ r: "10px" }}
            />
            <Div p={{ l: "1rem" }}>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textWeight="bold"
                p={{ t: "5px" }}>
                Versatility
              </Text>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textColor="gray900">
                We will develop, integrate, rollout and maintain your Web3
                project.
              </Text>
            </Div>
          </Div>
          <Div d="flex" m={{ b: "15px" }}>
            <Icon
              name="CBChecked"
              size="40px"
              color="#375bd2"
              p={{ r: "10px" }}
            />
            <Div p={{ l: "1rem" }}>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textWeight="bold"
                p={{ t: "5px" }}>
                Scalability
              </Text>
              <Text
                textSize="subheader"
                d="flex"
                align="center"
                textColor="gray900">
                All of our soultions are built with scalability in mind, meaning
                that they can grow and evolve with your company. We help you
                plan for the future and make improvements when the time comes.
              </Text>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div
        m={{ t: "3rem", b: "4rem" }}
        w="100%"
        justify="center"
        textAlign="center"
        align="center"
        d="flex"
        flexDir={{ xs: "column", xl: "row" }}
        p="4rem"
        textColor="white">
        <Div
          w={{ xl: "40%", xs: "100%" }}
          d="flex"
          flexDir="column"
          textAlign="left"
          m={{ r: { xl: "15rem", xs: "0rem" } }}>
          <Text textSize="50px">
            Want to find out if your{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#375bd2",
              }}>
              project qualifies
            </span>
            ?
          </Text>
          <Text textSize="title" textColor="gray900" m={{ t: "1rem" }}>
            Get your{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#375bd2",
              }}>
              free
            </span>{" "}
            assessment to see if your company can be put on chain. Don't worry,
            you don't need any technical knowledge or understanding of
            blockchain.
          </Text>
          <Div d="flex" flexDir="row" m={{ t: "3rem" }} align="center">
            <Button
              w="15rem"
              h="3.5rem"
              bg="#375bd2"
              rounded="15px"
              textSize="subheader">
              <Anchor
                textColor="white"
                href="https://calendly.com/jonjcoleman/15-minute-consultation/">
                Get Your Assessment
              </Anchor>
            </Button>
          </Div>
        </Div>
        <Div m={{ t: "3rem" }}>
          <Div>
            <Div
              w={{ xl: "30rem", xs: "22rem" }}
              h={{ xl: "25rem", xs: "15rem" }}
              bgImg={server_chain}
              rounded="15px"
              position="relative"
              bgSize="cover"
              bgPos="center"
              style={{ backgroundPosition: "-180px center" }}
            />
          </Div>
        </Div>
      </Div>
      <Div
        m={{ t: "3rem", b: "4rem" }}
        w="100%"
        justify="center"
        textAlign="center"
        align="center"
        d="flex"
        flexDir="column"
        p={{ xl: "4rem", xs: "2rem" }}>
        <Div
          d="flex"
          flexDir="column"
          align="center"
          bg="#375bd2"
          w={{ xl: "80%", xs: "90%" }}
          p={{ x: { xl: "10rem", xs: "2rem" }, y: "6rem" }}
          rounded="30px"
          textColor="white">
          <Div
            w="8rem"
            h="3rem"
            d="flex"
            align="center"
            justify="center"
            border="1px solid"
            borderColor="white"
            rounded="15px"
            opacity="0.7"
            m={{ b: "2rem" }}>
            Get In Touch
          </Div>
          <Text textSize={{ xl: "60px", xs: "40px" }} m={{ b: "4rem" }}>
            Want to learn more about Web3?
          </Text>

          <Subscribe />
        </Div>
      </Div>
    </Div>
  );
};
